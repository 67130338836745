import { JackIcons } from "../../assets/jackIcons/parent";
import { GothamMedium, GothamRegular } from "../../components/Text";
import { colors, jackColors } from "../../assets/colors";
import styles from "./layout.module.css";
import { useRouter } from "next/router";
import { useModalHook } from "../../components/Modals";
import { useEffect, useState } from "react";
import { EnvModal } from "../loginComponents/envModal";
import { windowDimension } from "../../components/tools";
import { useLayout } from "../../contexts/Layout/parent";

export const Layout = ({
  children,
  title,
  isSignedIn,
  icon,
  setCurrentScreen,
  woJumper,
  subTitle,
  title100,
  woGap,
  woIcon = false,
  wrapperStyle,
  formStyle,
}) => {
  const { push } = useRouter();
  const { isTabOrPhone: isResponsive } = windowDimension();

  const [counter, setCounter] = useState(0);
  const { isOpen, toggle } = useModalHook();
  const { scrollTop } = useLayout();

  useEffect(() => {
    const openModal = counter >= 10;
    if (!openModal) return;
    setCounter(0);
    toggle();
  }, [counter]);

  const onClick = () => {
    if (woJumper) return;
    if (isSignedIn) return;
    setCounter((prev) => prev + 1);
    push("/login");
    setCurrentScreen("login");
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        paddingTop: 140,
        paddingBottom: 32,
        paddingLeft: isResponsive ? 16 : 0,
        paddingRight: isResponsive ? 16 : 0,
        ...wrapperStyle,
      }}
    >
      {!woIcon && (
        <div
          className={`${isSignedIn ? styles["logo"] : ""} ${
            isSignedIn ? styles["signed-in"] : ""
          }`}
          style={{
            top: isSignedIn && 32 - scrollTop,
            marginTop: !isSignedIn && 32 - 140,
            marginBottom: !isSignedIn && 16,
            cursor: !isSignedIn && "pointer",
          }}
          onClick={onClick}
        >
          <JackIcons name="jack-horizontal" fill="black" />
        </div>
      )}
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: woGap ? 0 : "32px",
          width: "100%",
          maxWidth: "438px",
          opacity: isSignedIn ? "0" : "1",
          transition: "opacity 0.2s ease-in-out",
          ...formStyle,
        }}
      >
        {icon && icon}
        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            gap: "8px",
            alignItems: "center",
          }}
        >
          <GothamMedium
            style={{
              color: colors.neutral900,
              textAlign: "center",
              width: title100 ? "100%" : isResponsive ? "95%" : "80%",
            }}
            className="font24"
          >
            {title}
          </GothamMedium>
          <GothamRegular
            style={{
              color: colors.neutral700,
              textAlign: "center",
              width: "100%",
            }}
          >
            {subTitle}
          </GothamRegular>
        </div>
        {children}
      </div>
      <EnvModal isOpen={isOpen} toggle={toggle} />
    </div>
  );
};

export const passwordCheckerProps = (passwordRaw) => {
  const password = passwordRaw || "";
  const checkers = [
    {
      text: "8 Characters minimum",
      isPassed: password.length >= 8,
    },
    {
      text: "One number",
      isPassed: /\d/.test(password),
    },
    {
      text: "One uppercase character",
      isPassed: /[A-Z]/.test(password),
    },
    {
      text: "One lowercase character",
      isPassed: /[a-z]/.test(password),
    },
  ];
  const isValid = !checkers.find(({ isPassed }) => !isPassed);

  return { isValid, checkers };
};

export const PasswordChecker = ({
  password = "",
  className: classNameProps,
  isError,
}) => {
  const { checkers } = passwordCheckerProps(password);

  const containerStyle = {
    display: "flex",
    flexDirection: "column",
    gap: "13px",
  };

  const rowStyle = {
    display: "flex",
    alignItems: "center",
    gap: "9px",
  };

  return (
    <div className={classNameProps} style={containerStyle}>
      {checkers.map(({ text, isPassed }, index) => {
        const error = isError && !isPassed;
        return (
          <div style={rowStyle} key={index}>
            <JackIcons
              name={isPassed ? "check-circle-outline" : "info-outline"}
              fill={
                error
                  ? jackColors.redE7
                  : isPassed
                  ? colors.green06
                  : colors.neutral500
              }
            />
            <GothamRegular
              style={{
                color: error ? jackColors.redE7 : colors.neutral900,
                transition: "all 0.4s linear",
              }}
              className="font12"
            >
              {text}
            </GothamRegular>
          </div>
        );
      })}
    </div>
  );
};
