import { Fragment, useRef } from "react";
import { JackIcons } from "../../../assets/jackIcons/parent";
import {
  GothamMedium,
  GothamRegular,
  TextInlineRegular,
} from "../../../components/Text";
import { useModalHook } from "components/Modals";
import { ButtonJack } from "components/ButtonsJack/parent";
import { useRouter } from "next/router";
import { jackColors } from "assets/colors";

export const SidebarRegister = ({
  wrapperStyle,
  wrapperClass,
  withNavMenu = false,
  iconStyle,
  currentBanner = "general",
}) => {
  const navMenuRef = useRef();
  const { isOpen, toggle } = useModalHook();
  const bannerTitle = {
    general: "Get started with Jack. Register Now!",
    transfez_app:
      "Receive Better Exchange Rates and Get 3 Transaction Vouchers. Register Now!",
  };
  const bannerArrays = {
    general: [
      {
        title: "One stop solution",
        msg: "Payment tools that benefit and ease you in managing your business.",
      },
      {
        title: "24/7 availability",
        msg: "Our customer service is ready to assist you, every day, even on weekends.",
      },
      {
        title: "Secure transactions",
        msg: "Jack is licensed by Bank Indonesia, supervised by PPATK and KOMINFO.",
      },
    ],
    transfez_app: [
      {
        title: "One stop solution",
        msg: "Payment tools that benefit and ease you in managing your business.",
      },
      {
        title: "24/7 availability",
        msg: "Our customer service is ready to assist you, every day, even on weekends.",
      },
      {
        title: "Secure transactions",
        msg: "Jack is licensed by Bank Indonesia, supervised by PPATK and KOMINFO.",
      },
    ],
  };

  const imagesObject = {
    general: "url(/images/sidebar2.png)",
    transfez_app: "url(/images/asset-register-2.webp)",
  };

  const arr = bannerArrays[currentBanner];
  // return <img src="/images/sidebar2.png" />;
  return (
    <div
      style={{
        backgroundColor: "black",
        height: "100vh",
        minHeight: 856,
        width: 432,
        padding: 32,
        paddingTop: 20,
        backgroundImage: imagesObject[currentBanner],
        backgroundSize: "cover", // Optionally adjust the background size
        backgroundRepeat: "no-repeat", // Optionally adjust the background repeat behavior
        position: "relative",
        // You can add more background properties here as needed
        ...wrapperStyle,
      }}
      className={wrapperClass}
    >
      <div
        className="d-flex"
        style={
          withNavMenu
            ? { marginLeft: -25, justifyContent: "space-between" }
            : { marginLeft: -25 }
        }
      >
        <JackIcons name="jack-horizontal" fill="white" style={iconStyle} />
        {withNavMenu && (
          <JackIcons
            name={isOpen ? "close-outline" : "menu-outline"}
            fill="white"
            onClick={() => toggle()}
          />
        )}
        <ModalNavMenu ref={navMenuRef} isOpen={isOpen} />
      </div>
      <GothamMedium
        className="font24"
        style={{ color: "white", marginTop: 32 }}
      >
        {bannerTitle[currentBanner]}
      </GothamMedium>
      {arr.map((item, index) => {
        const { title, msg } = item;
        return (
          <Fragment key={index}>
            <div
              className="d-flex align-items-center"
              style={{ marginTop: 24 }}
            >
              <JackIcons
                name="logogram"
                style={{ width: 24, marginRight: 12, height: 24 }}
              />
              <GothamMedium className="font16" style={{ color: "white" }}>
                {title}
              </GothamMedium>
            </div>
            <div className="d-flex" style={{ marginTop: 8 }}>
              <div style={{ width: 24 + 24 }} />
              <GothamRegular style={{ color: "white" }}>{msg}</GothamRegular>
            </div>
          </Fragment>
        );
      })}

      <GothamRegular
        className="font16"
        style={{ color: "white", marginTop: 24 }}
      >
        Trusted and Used by Thousands of Businesses in Indonesia!
      </GothamRegular>
    </div>
  );
};

export const ModalNavMenu = ({
  ref = { current: null },
  modalStyle,
  isOpen,
  isWhite = false,
}) => {
  const { push } = useRouter();

  if (!isOpen) return null;

  return (
    <div
      ref={ref}
      style={{
        position: "absolute",
        width: "100%",
        // height: "72px",
        backgroundColor: isWhite ? jackColors.neutral200 : "black",
        top: 115,
        left: 0,
        zIndex: 100,
        boxShadow: "0px 8px 16px -4px #16223314",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        gap: "16px",
        padding: "24px 16px",
        borderTop: isWhite ? `1px solid ${jackColors.neutral500}` : "",

        ...modalStyle,
      }}
    >
      <GothamRegular
        style={{
          color: isWhite ? jackColors.neutral900 : jackColors.neutral100,
        }}
      >
        Already have an account?
      </GothamRegular>
      <ButtonJack
        style={{ width: "100%", backgroundColor: isWhite ? "" : "white" }}
        onClick={() => push("/login")}
      >
        Log In
      </ButtonJack>
    </div>
    // <div style={{ ...containerStyle, position: "relative", maxWidth: "100%" }}>
    // </div>
  );
};
