import { ButtonJack } from "components/ButtonsJack/parent";
import { jackColors } from "../../../assets/colors";
import { GothamRegular, TextInlineRegular } from "../../../components/Text";
import { CheckEmailComponent } from "./component";
import { JackIcons } from "assets/jackIcons/parent";
import { useRouter } from "next/router";
import { windowDimension } from "@tools";
import { ModalNavMenu, SidebarRegister } from "../register/sidebar";
import { useModalHook } from "components/Modals";
import { useRef } from "react";

const CheckEmailPageComponents = ({
  type,
  emailSent,
  setCurrentScreen,
  mutationForgotPassword,
  mutationResendRegister,
}) => {
  const { push, query } = useRouter();
  const { isOpen, toggle } = useModalHook();
  const { isTabOrPhone: isResponsive, width } = windowDimension();
  const navRef = useRef();
  // return null;
  const isNewUser = !!query?.banner;

  return (
    <div style={{ display: "flex", width: isResponsive ? width : "100%" }}>
      {!isResponsive && isNewUser && (
        <div style={{ minWidth: 432 }}>
          <SidebarRegister
            withNavMenu={isResponsive}
            wrapperStyle={
              isResponsive
                ? { width: "fit-content" }
                : { position: "fixed", top: "0px" }
            }
            // wrapperClass={registerStyles["sidebar-content"]}
          />
        </div>
      )}
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          width: isResponsive ? width : "100%",
        }}
      >
        <ModalNavMenu
          ref={navRef}
          isOpen={isOpen}
          isWhite
          modalStyle={{ top: 90 }}
        />
        <div
          className={`w-100 align-items-center justify-content-${
            isResponsive ? "start" : "end"
          } d-flex`}
          style={{
            padding: isResponsive ? "0px 20px" : 20,
            paddingRight: 20,
            gap: 16,
          }}
        >
          {isResponsive && (
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <JackIcons
                name="jack-horizontal"
                fill="black"
                styleDiv={{ padding: 0 }}
                style={{ width: 100, marginLeft: -10 }}
              />
              <JackIcons
                name={isOpen ? "close-outline" : "menu-outline"}
                fill="black"
                onClick={() => toggle()}
              />
            </div>
          )}
          {!isResponsive && (
            <>
              {isResponsive && (
                <GothamRegular style={{ color: jackColors.neutral700 }}>
                  Already have an account?
                </GothamRegular>
              )}
              <ButtonJack
                type="outline"
                rightIcon={<JackIcons name="log-out-outline" fill="black" />}
                onClick={() => push("/login")}
              >
                Log in
              </ButtonJack>
            </>
          )}
        </div>
        <CheckEmailComponent
          title="We’ve sent you the link!"
          subTitle={
            <>
              Click the link we’ve sent to{" "}
              <TextInlineRegular style={{ color: jackColors.black34 }}>
                {emailSent}
              </TextInlineRegular>{" "}
              {type === "checkEmailRegister"
                ? "and follow the next instruction to activate your account."
                : "to reset your password."}
            </>
          }
          instruction="Didn’t get the email?"
          sendAgainInfo="Check spam folder or"
          sendAgainText="get a new link"
          setCurrentScreen={setCurrentScreen}
          onSendAgain={async (afterSuccessSendAgain) => {
            const isRegister = type === "checkEmailRegister";
            if (isRegister) await mutationResendRegister({ email: emailSent });
            else await mutationForgotPassword({ email: emailSent });
            afterSuccessSendAgain();
          }}
        />
      </div>
    </div>
  );
};

export default CheckEmailPageComponents;
